/* VerificationForm.css */
.form-container {
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  
  .form-container form {
    display: flex;
    flex-direction: column;
  }
  
  .form-container label {
    margin-bottom: 20px;
  }
  
  .form-container input[type='text'],
  .form-container input[type='date'] {
    padding: 8px;
    margin-bottom: 15px;
    margin-left: 10px;
    border-radius: 3px;
    border: 1px solid #ccc;
  }
  
  .form-container button {
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .form-container button:hover {
    background-color: #0056b3;
  }
  
/* Styles for smaller screens */
@media (max-width: 600px) {
    .form-container {
      width: 90%;
      margin: 0 auto;
      padding: 10px;
}}
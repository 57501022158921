/* App.css */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px; /* Adjust margin as needed */
}

.logo {
  width: 500px; /* Adjust size as needed */
  height: 80px; /* Adjust size as needed */
  margin-bottom: 20px; /* Adjust spacing as needed */
}

.verification-page-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
